import React from "react";
import * as styles from './index.module.scss';

const CaseItem = (props) => {
  const { name, num, icon } = props;
  return (
    <div className={styles.caseItem}>
      <img className={styles.icon} src={icon} alt="" />
      <div className={styles.num}>{num || 0}</div>
      <div className={styles.name}>{name}</div>
    </div>
  )
}

export default React.memo(CaseItem);