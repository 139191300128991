import React, { useState, useEffect } from "react";
import * as api from '@/api';
import * as styles from './index.module.scss';
import userDefaultImg  from '@/assets/images/userDefault.png'
const MediatorList = (props) => {
  const [mediatorList, setMediatorList] = useState([]);
  useEffect(() => {
    api.getMediatorList().then((res) => {
      setMediatorList(res.data || []);
    });
  }, [])
  return (
    <div className={styles.userList}>
      {mediatorList.map((item, index) => (
        <div className={styles.mediator_wrapper} key={index}>
          <img className={styles.avatar} src={item.photoPath} alt="" onError={(e)=>{
            const img = e.currentTarget;
             img.src = userDefaultImg;
             img.οnerrοr = null;
          }}/>
          <div className={styles.mediator_info}>
            <div>
              <span className={styles.title}>姓名</span>
              <span className={styles.value}>{item.userName}</span>
            </div>
            <div>
              <span className={styles.title}>编号</span>
              <span className={styles.value}>{item.userCode}</span>
            </div>
            <div>
              <span className={styles.title}>职位</span>
              <span className={styles.value}>调解员</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default React.memo(MediatorList);