import React from "react";
import MenuItem from './components/MenuItem/index';
import * as styles from './index.module.scss';

const FooterMenu = () => {
  return (
    <div className={styles.footerMenuWrapper}>
      <div className={styles.footerMenu}>
        <MenuItem
          name="中国庭审直播公开"
          link="http://tingshen.court.gov.cn/"
          imgSrc={require('@/assets/images/footer-icon2.png')}
        />
        <MenuItem
          name="中国审判流程信息公开"
          link="https://splcgk.court.gov.cn/gzfwww/"
          imgSrc={require('@/assets/images/footer-icon1.png')}
        />
        <MenuItem
          name="中国裁判文书公开"
          link="http://wenshu.court.gov.cn/"
          imgSrc={require('@/assets/images/footer-icon3.png')}
        />
        <MenuItem
          name="中国执行信息公开"
          link="http://zxgk.court.gov.cn/"
          imgSrc={require('@/assets/images/footer-icon4.png')}
        />
        <MenuItem
          name="全国法院统一送达平台"
          link="http://songda.court.gov.cn/"
          imgSrc={require('@/assets/images/footer-icon5.png')}
        />
        <MenuItem
          name="最高人民法院诉讼服务网"
          link="http://ssfw.court.gov.cn/ssfww"
          imgSrc={require('@/assets/images/footer-icon6.png')}
        />
      </div>
    </div>
  )
}

export default React.memo(FooterMenu);