import React, { useState, useEffect, useRef } from "react";
import { Carousel } from 'antd';
import { Link } from 'react-router-dom';
import CaseItem from './components/CaseItem/index';
import ModuleBox from './components/ModuleBox/index'
import ALink from '@/components/Alink';
import * as api from '@/api/index.js';
import styles from './index.module.scss';
import CaseSearch from './components/CaseSearch/index';

const Home = (props) => {
  const [carouselIndex, setCarouseIndex] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [caseInfo, setCsaeInfo] = useState({});
  const [lawsuitList, setLawsuitList] = useState([]);
  const [remedyList, setRemedyList] = useState([]);
  const carouseRef = useRef(null);
  const handleBeforeChange = (_, to) => {
    setCarouseIndex(to);
  };

  const handleCarouselGoTo = (index) => {
    carouseRef.current && carouseRef.current.goTo(index)
  }

  useEffect(() => {
    api.getBannerList().then(res => {
      setBannerList(res?.data);
    });
    // api.getCaseInfo().then(res => {
    //   setCsaeInfo(res?.data || {});
    // });
    api.searchNewsInfo({
      newsColumnId: 6,
      pageNum: 1,
      pageSize: 8,
    }).then(res => {
      setLawsuitList(res?.data?.list || []);
    });
    api.searchNewsInfo({
      newsColumnId: 7,
      pageNum: 1,
      pageSize: 8,
    }).then(res => {
      setRemedyList(res?.data?.list || [])
    });
  }, []);
  return (
    <div className={styles.main}>
      <div className={styles.bannerCourt}>
        <div className={styles.carouselSwiper}>
          <Carousel
            ref={carouseRef}
            effect="fade"
            dots={false}
            autoplay
            beforeChange={handleBeforeChange}
          >
            {bannerList.map((item, index) => (
              <div className={styles.img_box} key={index}>
                <img src={item.picUrl} alt="" />
              </div>
            ))}
          </Carousel>
          <div className={styles.carouselNav}>
            <ALink href={bannerList[carouselIndex]?.detailUrl}>
              <div className={styles.banner_title}>{bannerList[carouselIndex]?.title || ''}</div>
            </ALink>
            <div className={styles.dotWrapper}>
              {bannerList.map((_, index) => (
                <span
                  className={`${styles.dot} ${carouselIndex === index && styles.active}`}
                  key={index}
                  onClick={() => handleCarouselGoTo(index)}
                >{index + 1}</span>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.courtsList}>
          <h3>
            <img src={require('@/assets/images/position-place.png')} alt="" />
             <span style={{marginLeft:12}}>案件查询</span>
          </h3>
          <CaseSearch history={props.history}/>
          <div className={styles.phoneNumber}>
            <img src={require('@/assets/images/tjzx_03.png')} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.diffCaseWrapper}>
        <CaseItem name="累计调解案件" num={caseInfo.ingCount || 14976} icon={require('@/assets/images/statistics-court.png')} />
        <CaseItem name="已调解成功案件" num={caseInfo.registerCount || 8467} icon={require('@/assets/images/statistics-org.png')} />
        <CaseItem name="已提交法院诉讼案件" num={caseInfo.decisionCount || 2387} icon={require('@/assets/images/statistics-tjy.png')} />
        <CaseItem name="已完成案件" num={caseInfo.completeCount || 12534} icon={require('@/assets/images/statistics-case.png')} />
      </div>
      <ModuleBox title="关于我们">
        <div className={styles.content}>
          <p>
          荆州市荆州区飞诉金融纠纷调解中心是由荆州市司法局 业务主管，荆州市民政局注册登记的专业调解组织。中心主要职能为受理平等主体的法人、自然人和其他组织之间在金融交易、金融服务等活动中发生的民商事争议纠纷而提出的调解申请，受托办理金融类案件纠纷调解;开展金融法律知识宣传、经济金融纠纷调解的理论研究和法律咨询服务等。  
          </p>
        </div>
      </ModuleBox>
      <ModuleBox title="解纷流程--全流程一站式解纷服务">
        <img className={styles.progressImg} src={require('@/assets/images/tjzx_07.png')} alt="" />
      </ModuleBox>
      <ModuleBox title="金融纠纷诉调对接流程">
        <img className={styles.progressImg} src={require('@/assets/images/tjzx_11.png')} alt="" />
      </ModuleBox>
      {/* <ModuleBox title="金融纠纷审判流程">
        <img className={styles.progressImg} src={require('@/assets/images/tjzx_15.png')} alt="" />
      </ModuleBox> */}
      <ModuleBox title="金融纠纷强制执行流程">
        <img className={styles.progressImg} src={require('@/assets/images/tjzx_17.png')} alt="" />
      </ModuleBox>

      <div className={styles.courtmediateWrap}>
        <ModuleBox wrapStyle={{ width: '580px' }} title="诉讼服务指南" link="/detail/6">
          <div className={styles.guideListMore}>
            {(lawsuitList || []).map((item, index) => (
              <div className={styles.titleListItem} key={index}>
                <Link className={styles.title} to="/detail/6">{item.title}</Link>
                <span className={styles.date}>{item.publishDay || ''}</span>
              </div>
            ))}
          </div>
        </ModuleBox>
        <ModuleBox wrapStyle={{ width: '580px' }} title="解纷指南" link="/detail/7">
          <div className={styles.guideListMore}>
            {(remedyList || []).map((item, index) => (
              <div className={styles.titleListItem} key={index}>
                <Link className={styles.title} to="/detail/7">{item.title}</Link>
                <span className={styles.date}>{item.publishDay || ''}</span>
              </div>
            ))}
          </div>
        </ModuleBox>
      </div>
    </div>
  )
}

export default React.memo(Home);