import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Card, SpinLoading } from 'antd-mobile';
import CaseProgress from './CaseProgress';

import * as api from '@/api/index.js';

import * as styles from './index.module.scss';

const CaseDetail = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [caseInfo, setCaseInfo] = useState({});

  useEffect(() => {
    const { idcard, tel, verifyCode } = props?.match?.params || {};
    api.searchCaseDetailByIdcard({
      idcard,
      tel,
      verifyCode,
    }).then(res => {
      const { code, data } = res || {};
      if (+code === 10000) {
        setCaseInfo(data || {});
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {isLoading ? (
        <>
          <SpinLoading className={styles.loading} color='#1677ff' />
          <p className={styles.loading_text}>数据加载中...</p>
        </>
      ) : (
        <>
          <Card title='案件总览'>
            <div>
              <span className={styles.label}>姓名：</span>
              <span>{caseInfo.borrowerName}</span>
            </div>
            <div>
              <span className={styles.label}>案件数量：</span>
              <span>{caseInfo.caseCount}</span>
            </div>
            <div>
              <span className={styles.label}>欠款总额：</span>
              <span>{caseInfo.sumAmount}</span>
            </div>
          </Card>
          <Card title='案件列表'>
            {(caseInfo.caseList || []).map((item, index) => (
              <div className={styles.list_item} key={index}>
                <h4>案件进展:</h4>
                <div className={styles.list_item_inner}>
                  <CaseProgress caseStatus={item.caseStatus} />
                </div>
                <h4>案件信息:</h4>
                <div className={styles.list_item_inner}>
                  <div className={styles.info_item}>
                    <div span={3}>案件id：</div>
                    <div span={9}>{item.caseId || '-'}</div>
                  </div>
                  <div className={styles.info_item}>
                    <div span={3}>案件查验码:</div>
                    <div span={9}>{item.contractCode || '-'}</div>
                  </div>
                  <div className={styles.info_item}>
                    <div span={3}>金调案号：</div>
                    <div span={9}>{item.borrowerIdcard || '-'}</div>
                  </div>
                  <div className={styles.info_item}>
                    <div span={3}>被告姓名：</div>
                    <div span={9}>{item.borrowerName || '-'}</div>
                  </div>
                  <div className={styles.info_item}>
                    <div span={3}>诉讼金额：</div>
                    <div span={9}>{item.originMoney || '-'}</div>
                  </div>
                </div>
                <h4 className={styles.title}>调解员信息:</h4>
                <div className={styles.list_item_inner}>
                  <div className={styles.info_item}>
                    <div span={3}>调解员：</div>
                    <div span={9}>{item.userName || '-'}</div>
                  </div>
                  <div className={styles.info_item}>
                    <div span={3}>调解员座机号：</div>
                    <div span={9}>{item.userTel || '-'}</div>
                  </div>
                </div>
              </div>
            ))}
          </Card>
        </>
      )}
    </div>
  )
}

export default React.memo(withRouter(CaseDetail));