import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ALL_NAV_LIST } from '@/config/index.js';
import styles from './index.module.scss';

const HEADER_NAV_LIST = [ '调解员名录', '调解指南','调解案例'];

const HeaderNav = (props) => {
  const { pathname = '' } = props?.location || {};
  const pathnameList = pathname.split('/');
  let id = '';
  if (pathnameList[1] === 'detail') {
    id = pathnameList[2];
  }
  return (
    <>
      <div className={styles.headerNav}>
        <div className={styles.layout}>
          <Link to="/">
            <span className={styles.logo}>飞诉金融纠纷调解中心</span>
          </Link>
          <div className={styles.menus}>
            <Link className={`${['/', '/home'].includes(pathname) && styles.active}`} to="/home">首页</Link>
            {HEADER_NAV_LIST.map((name, index) => {
              const navItemInfo = ALL_NAV_LIST.find(item => item.name === name) || {};
              return (
                <Link
                  to={`/detail/${navItemInfo.id}`}
                  className={`${+id === navItemInfo.id && styles.active}`}
                  key={index}
                >{name}</Link>
              )
            })}
              <Link target="_blank" className={`${['case-search', '/case-search'].includes(pathname) && styles.active}`} to="/case-search">案件查询</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(withRouter(HeaderNav));