import React, { useState, useEffect } from "react";
import * as api from '@/api/index.js';
import * as styles from './index.module.scss';

const CsaeDetail = (props) => {
  const { id } = props;

  const [caseDetail, setCaseDetail] = useState({});

  useEffect(() => {
    api.getNewsInfo({ id }).then(res => {
      setCaseDetail(res.data);
    });
  }, [id]);

  return (
    <div className={styles.case_detail} dangerouslySetInnerHTML={{ __html: caseDetail.detail }} />
  )
}

export default React.memo(CsaeDetail);