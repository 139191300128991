import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '@/components/Header';
import HeaderNav from '@/components/HeaderNav';
import FooterMenu from '@/components/FooterMenu';
import Footer from '@/components/Footer';
import Home from '@/pages/Home';
import Detail from './pages/Detail';
import styles from './App.module.scss';

const { Content } = Layout;

console.log('app');
function App(props) {

  return (
    <div className="App">
      <Header />
      <HeaderNav />
      <Layout>
        <Content className={styles.main}>
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/detail/:id/:caseId?" component={Detail} />
          </Switch>
        </Content>
      </Layout>
      <FooterMenu />
      <Footer />
    </div>
  );
}

export default withRouter(App);
