import React from "react";
import styles from './index.module.scss';

const CommonTitle = (props) => {
  const { title } = props || {};
  return (
    <div className={styles.common_title}>
      <img className={styles.icon} src={require('@/assets/images/help-ask-ico.png')} alt="" />
      <h3 className={styles.title}>{title}</h3>
    </div>
  )
}

export default React.memo(CommonTitle);