import React from "react";
import { Link } from 'react-router-dom';
import * as styles from './index.module.scss';


const ModuleBox = (props) => {
  const { title, link, children, wrapStyle = {} } = props;
  return (
    <div className={styles.moduleBox} style={wrapStyle}>
      <div className={styles.boxTitle}>
        <div>{title}</div>
        {link && <Link to={link} className={styles.link}>查看更多 &gt;</Link>}
      </div>
      {children}
    </div>
  )
}

export default React.memo(ModuleBox);