import React from "react";
import { Link } from "react-router-dom";
import * as styles from './index.module.scss';

const NewsListItem = (props) => {
  const { itemInfo = {}, isView } = props || {};

  return (
    <div className={styles.news_list_item}>
      <p className={`ellipsis ${styles.title}`}>{itemInfo.title}</p>
      {isView ? (
        <Link className={styles.view} to={`/detail/3/${itemInfo.id}`}>点击查看&gt;&gt;</Link>
      ) : (
        <span>{itemInfo.publishDay}</span>
      )}
    </div>
  )
}

export default React.memo(NewsListItem);