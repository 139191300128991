import React from "react";
import ALink from '@/components/Alink';
import * as styles from './index.module.scss';

const COURT_NAV_LIST = [
  {
    title: '中国庭审直播公开',
    icon: require('@/assets/images/footer-icon2.png'),
    link: 'http://tingshen.court.gov.cn/',
  },
  {
    title: '中国审判流程信息公开',
    icon: require('@/assets/images/footer-icon1.png'),
    link: 'https://splcgk.court.gov.cn/gzfwww/',
  },
  {
    title: '中国裁判文书公开',
    icon: require('@/assets/images/footer-icon3.png'),
    link: 'http://wenshu.court.gov.cn/',
  },
  {
    title: '中国执行信息公开',
    icon: require('@/assets/images/footer-icon4.png'),
    link: 'http://zxgk.court.gov.cn/',
  },
  {
    title: '全国法院统一送达平台',
    icon: require('@/assets/images/footer-icon5.png'),
    link: 'http://songda.court.gov.cn/',
  },
  {
    title: '最高人民法院诉讼服务网',
    icon: require('@/assets/images/footer-icon6.png'),
    link: 'http://ssfw.court.gov.cn/ssfww',
  },
]

const CourtNav = (props) => {
  return (
    <div className={styles.list_wrapper}>
      {COURT_NAV_LIST.map((item, index) => (
        <div className={styles.list_item} key={index}>
          <ALink href={item.link} target="_blank" >
            <img className={styles.icon} src={item.icon} alt="" />
            <p className={styles.title}>{item.title}</p>
          </ALink>
        </div>
      ))}
    </div>
  )
}

export default React.memo(CourtNav);