import React, { useState, useEffect, useCallback } from "react";
import { Pagination } from 'antd';
import { withRouter } from 'react-router-dom';
import CommonTitle from '@/components/CommonTitle';
import NewsListItem from '@/components/NewsListItem/';
import CsaeDetail from '@/components/CsaeDetail';
import * as api from '@/api/index.js';
import * as styles from './index.module.scss';

const PAGE_SIZE = 20;

const NewsList = (props) => {
  const { id, caseId, title = '标题' } = props || {};
  const [newsList, setNewsList] = useState([]);
  const [total, setTotal] = useState(0);

  const getList = useCallback((page) => {
    api.searchNewsInfo({
      newsColumnId: id,
      pageNum: page || 1,
      pageSize: PAGE_SIZE,
    }).then(res => {
      const { list, total } = res?.data || {};
      setNewsList(list || []);
      setTotal(total || 0);
    });
  }, [id])

  const handlePageChange = (page) => {
    getList(page)
  }

  const handleView = (info) => {
    info.id && props.history.replace(`/detail/3?id=${info.id}`)
  }

  useEffect(() => {
    getList(1);
  }, [getList]);

  return (
    <>
      <CommonTitle title={caseId ? newsList.find(item => item.id === caseId)?.title : title} />
      <div className={styles.list_wrap}>
        {!caseId ? (newsList || []).map((item, index) => (
          <NewsListItem itemInfo={item} key={index} isView={+id === 3} handleView={handleView} />
        )) : (
          <CsaeDetail id={caseId} />
        )}
        {total > PAGE_SIZE && <Pagination defaultCurrent={1} total={total} onChange={handlePageChange} />}
      </div>
    </>
  )
}

export default React.memo(withRouter(NewsList));