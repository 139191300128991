import React from 'react';
import dayjs from 'dayjs';

import styles from './index.module.scss';

const DAYS = ['日', '一', '二', '三', '四', '五', '六'];

const Header = () => {
  return (
    <>
      <div className={styles.headerTop}>
        <div className={styles.layout}>
          今天是：{dayjs(Date.now()).format('YYYY年MM月DD日')} 星期{DAYS[dayjs().day()]}
        </div>
      </div>
    </>
  )
}

export default React.memo(Header);