import React from "react";
import { withRouter, Link } from 'react-router-dom';
import Breadcrumb from '@/components/Breadcrumb';
import MediatorList from './components/MediatorList';
import NewsList from './components/NewsList';
import DisputeGuide from './components/DisputeGuide';
import CourtNav from './components/CourtNav';
import Introduction from './components/Introduction';
import { NAV_LIST, ALL_NAV_LIST, INTRODUCTION_ID_LIST } from '@/config/index.js';
import * as styles from './index.module.scss';

const Detail = (props) => {
  const { id, caseId } = props?.match?.params || {};
  const detailInfo = ALL_NAV_LIST.find(item => item.id === +id) || {};
  const breadcrumbList = [
    {
      name: '首页',
      link: '/home',
    },
    {
      name: detailInfo?.name,
    }
  ]
  return (
    <div className={styles.detail}>
      <Breadcrumb list={breadcrumbList} />
      <div className={styles.help_peo}>
        {!detailInfo.hideNav && (
          <div className={styles.help_nav}>
            {(NAV_LIST || []).map((item, index) => (
              <Link
                className={`${styles.help_nav_item} ${+id === item.id && styles.active}`}
                to={`/detail/${item.id}`}
                key={index}
              >{item.name}</Link>
            ))}
          </div>
        )}
        <div className={styles.help_detail}>
          {[3, 4, 5, 6].includes(+id) && <NewsList key={`${id}-${caseId || ''}`} id={id} caseId={caseId} title={detailInfo.title} />}
          {id === '7' && <DisputeGuide />}
          {id === '10000' && <MediatorList />}
          {id === '10001' && <CourtNav />}
          {INTRODUCTION_ID_LIST.includes(+id) && <Introduction id={+id} key={id} />}
        </div>
      </div>
    </div>
  )
}

export default React.memo(withRouter(Detail));