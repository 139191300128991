import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import 'lib-flexible';

import CaseSearch from '@/pages/Mobile/CaseSearch';
import CaseDetail from '@/pages/Mobile/CaseDetail';
// import styles from './Mobile.module.scss';



function Mobile() {
  return (
    <div className="App">
      <Switch>
        <Route path="/case-search-mobile" component={CaseSearch} />
        <Route path="/case-detail-mobile/:idcard/:tel/:verifyCode" component={CaseDetail} />
      </Switch>
    </div>
  );
}

export default withRouter(Mobile);
