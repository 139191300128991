import React, { Fragment } from "react";

import * as styles from './index.module.scss';

const MEDIATE_LIST = [{
  label: '调解受理',
  value: 1,
}, {
  label: '正式调解',
  value: 2,
}, {
  label: '调解结案',
  value: 3,
}];

const LAWSUIT_LIST = [{
  label: '诉讼准备',
  value: 4,
}, {
  label: '诉讼申请',
  value: 5,
}, {
  label: '正式立案',
  value: 6,
}]

const CaseProgress = (props) => {
  const { caseStatus } = props;
  return (
    <div className={styles.progress_wrapper}>
      <div className={styles.progress_item}>
        <span>调解阶段：</span>
        <div className={styles.progress_block}>
          {MEDIATE_LIST.map((item, index) => (
            <Fragment key={index}>
              <section className={+caseStatus === item.value ? styles.active : ''}>{item.label}</section>
              {MEDIATE_LIST[index + 1] && (<span className={styles.arrow}>
                <span className={styles.dash_line}></span>
              </span>)}
            </Fragment>
          ))}
        </div>
      </div>
      <div className={styles.broken_line}>
        <span className={styles.line_right}></span>
        <span className={styles.line_middle}></span>
        <span className={styles.line_left}></span>
      </div>
      <div className={styles.progress_item}>
        <span>诉讼阶段：</span>
        <div className={styles.progress_block}>
          {LAWSUIT_LIST.map((item, index) => (
            <Fragment key={index}>
              <section className={+caseStatus === item.value ? styles.active : ''}>{item.label}</section>
              {LAWSUIT_LIST[index + 1] && (<span className={styles.arrow}>
                <span className={styles.dash_line}></span>
              </span>)}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default React.memo(CaseProgress);