import { request, MethodType } from '@/request.js';
const arbitrationCode = 'JZFS001';
export const getMediatorList = (params) => request({
  url: '/user/list',
  method: MethodType.POST,
  params:params?{...params,arbitrationCode}:{arbitrationCode},
});

export const getBannerList = (params) => request({
  url: '/banner/list',
  method: MethodType.POST,
  params,
});

export const getCaseInfo = (params) => request({
  url: '/caseInfo/getLast',
  params:params?{...params,arbitrationCode}:{arbitrationCode},
});

export const searchNewsInfo = (params) => request({
  url: '/newsInfo/search',
  method: MethodType.POST,
  params:params?{...params,arbitrationCode}:{arbitrationCode},
});

export const getNewsInfo = (params = {}) => request({
  url: `/newsInfo/get/${params.id}`,
});

export const searchCaseDetailByIdcard = (params) => request({
  url: '/caseInfo/searchByIdcard',
  method: MethodType.POST,
  params:params?{...params,arbitrationCode}:{arbitrationCode},
});

export const getVerifyCode = (params) => request({
  url: `/caseInfo/verifyCode/${params.tel}`,
});

export const getCaseFilePath = (params) => request({
  url: `/caseInfo/caseFilePath/${params.caseId}`,
});