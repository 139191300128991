import axios from 'axios';
import { message } from 'antd';

export const MethodType = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};


axios.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
})

axios.interceptors.response.use((config) => {
  const { code, msg } = config?.data || {};
  if (+code !== 10000) {
    message.error(msg);
  }
  return config
}, (error) => {
  return Promise.reject(error)
})


/**
 * 模块说明:有api_token的请求
 */
export const request = (reqData) => {
  const { url, method = MethodType.GET, params = {} } = reqData || {};
  const req_url = process.env.NODE_ENV === 'development' ? url : `/api${url}`;
  const data = (method === 'GET') ? 'params' : 'data';
  return new Promise((resolve, reject) => {
    axios({
      url: req_url,
      method,
      [data]: params,
    }).then(res => {
      // if (res?.data?.code === 1) {

      // } else if (res?.data?.code === 3) {
      //   window.location.href = `${window.location.href.split('#/')[0]}#/login`;
      // } else {
      //   resolve({});
      //   message.error(typeof res?.data?.content === 'string' ? res?.data?.content : '请求接口错误');
      // }
      resolve(res.data);

    }).catch(error => {
      message.error('接口请求报错');
      reject(error);
    });
  });
};
