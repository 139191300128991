import React from "react";
import { Link } from 'react-router-dom';
import * as styles from './index.module.scss';

const Breadcrumb = (props) => {
  const { list = [] } = props;

  return (
    <div className={styles.breadcrumb}>
      {list.map((item, index) => (
        <div className={styles.crumb} key={index}>
          {item.link ? (
            <Link to={item.link}>
              {item.name}
            </Link>
          ) : (
            <span className={styles.name}>{item.name}</span>
          )}

          {index < list.length - 1 && <img src={require('@/assets/images/next-gray-ico.png')} alt="" />}
        </div>
      ))}
    </div>
  )
}

export default React.memo(Breadcrumb);