import React from "react";
import * as styles from './index.module.scss';

const MenuItem = (props) => {
  const { name, imgSrc, link } = props;
  return (
    <>
      {link ? (
        <a className={styles.menuItem} href={link} target="_blank" rel="noopener noreferrer">
          <img src={imgSrc} alt="" />
          <p>{name}</p>
        </a>
      ) : (
        <div className={styles.menuItem}>
          <img src={imgSrc} alt="" />
          <p>{name}</p>
        </div>
      )}
    </>
  )
}

export default React.memo(MenuItem);