import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';
import CaseSearch from '@/pages/CaseSearch';
import CaseDetail from '@/pages/CaseDetail';
import { isMobile } from '@/utils/index.js';
import './index.css';

const Root = isMobile ? require('./Mobile').default : require('./App').default;

ReactDOM.render(
    <Router>
        <Route path="/home" component={Root} />
        <Route path="/detail" component={Root} />
        <Route path="/case-search" component={CaseSearch} />
        <Route path="/case-detail/:idcard/:tel/:verifyCode" component={CaseDetail} />
        <Route path="/case-search-mobile" component={Root} />
        <Route path="/case-detail-mobile" component={Root} />
        <Route exact path="/" render={() => <Redirect to="/home" />} />
    </Router >,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
