export const NAV_LIST = [{
  id: 3,
  name: '调解案例',
  title: '典型案例',
}, {
  id: 4,
  name: '调解指南',
  title: '调解指南',
}, {
  id: 10000,
  name: '调解员名录',
  title: '调解员名单',
}, {
  id: 5,
  name: '新闻动态',
  title: '新闻动态',
}, {
  id: 6,
  name: '诉讼服务指南',
  title: '诉讼服务指南',
}, {
  id: 7,
  name: '解纷指南',
  title: '最高人民法院关于人民法院特邀调解的规定',
}]

export const ALL_NAV_LIST = [
  ...NAV_LIST,
  // {
  //   id: 10001,
  //   name: '案件查询',
  //   hideNav: true,
  // },
  {
    id: 10002,
    name: '隐私声明',
    title: '隐私声明',
  },
  {
    id: 10003,
    name: '法律声明',
    title: '法律声明',
  },
  {
    id: 10004,
    name: '使用帮助',
    title: '使用帮助',
  },
  {
    id: 10005,
    name: '关于我们',
    title: '关于我们',
  },
]

export const INTRODUCTION_ID_LIST = [10002, 10003, 10004, 10005, 10006];