import React from "react";
import CommonTitle from '@/components/CommonTitle';
import * as styles from './index.module.scss';

const DisputeGuide = () => {
  return (
    <div>
      <CommonTitle title="最高人民法院关于人民法院特邀调解的规定" />
      <div className={styles.content_wrapper}>
        <p>2016年5月23日最高人民法院审判委员会第1684次会议通过，自2016年7月1日起施行）</p>
        <p>　　为健全多元化纠纷解决机制，加强诉讼与非诉讼纠纷解决方式的有效衔接，规范人民法院特邀调解工作，维护当事人合法权益，根据《中华人民共和国民事诉讼法》《中华人民共和国人民调解法》等法律及相关司法解释，结合人民法院工作实际，制定本规定。</p>
        <p>　　第一条特邀调解是指人民法院吸纳符合条件的人民调解、行政调解、商事调解、行业调解等调解组织或者个人成为特邀调解组织或者特邀调解员，接受人民法院立案前委派或者立案后委托依法进行调解，促使当事人在平等协商基础上达成调解协议、解决纠纷的一种调解活动。</p>
        <p>　　第二条特邀调解应当遵循以下原则：</p>
        <p>　　（一）当事人平等自愿；</p>
        <p>　　（二）尊重当事人诉讼权利；</p>
        <p>　　（三）不违反法律、法规的禁止性规定；</p>
        <p>　　（四）不损害国家利益、社会公共利益和他人合法权益；</p>
        <p>　　（五）调解过程和调解协议内容不公开，但是法律另有规定的除外。</p>
        <p>　　第三条人民法院在特邀调解工作中，承担以下职责：</p>
        <p>　　（一）对适宜调解的纠纷，指导当事人选择名册中的调解组织或者调解员先行调解；</p>
        <p>　　（二）指导特邀调解组织和特邀调解员开展工作；</p>
        <p>　　（三）管理特邀调解案件流程并统计相关数据；</p>
        <p>　　（四）提供必要场所、办公设施等相关服务；</p>
        <p>　　（五）组织特邀调解员进行业务培训；</p>
        <p>　　（六）组织开展特邀调解业绩评估工作；</p>
        <p>　　（七）承担其他与特邀调解有关的工作。</p>
        <p>　　第四条人民法院应当指定诉讼服务中心等部门具体负责指导特邀调解工作，并配备熟悉调解业务的工作人员。</p>
        <p>　　人民法庭根据需要开展特邀调解工作。</p>
        <p>　　第五条人民法院开展特邀调解工作应当建立特邀调解组织和特邀调解员名册。建立名册的法院应当为入册的特邀调解组织或者特邀调解员颁发证书，并对名册进行管理。上级法院建立的名册，下级法院可以使用。</p>
        <p>　　第六条依法成立的人民调解、行政调解、商事调解、行业调解及其他具有调解职能的组织，可以申请加入特邀调解组织名册。品行良好、公道正派、热心调解工作并具有一定沟通协调能力的个人可以申请加入特邀调解员名册。</p>
        <p>　　人民法院可以邀请符合条件的调解组织加入特邀调解组织名册，可以邀请人大代表、政协委员、人民陪审员、专家学者、律师、仲裁员、退休法律工作者等符合条件的个人加入特邀调解员名册。</p>
        <p>　　特邀调解组织应当推荐本组织中适合从事特邀调解工作的调解员加入名册，并在名册中列明；在名册中列明的调解员，视为人民法院特邀调解员。</p>
        <p>　　第七条特邀调解员在入册前和任职期间，应当接受人民法院组织的业务培训。</p>
        <p>　　第八条人民法院应当在诉讼服务中心等场所提供特邀调解组织和特邀调解员名册，并在法院公示栏、官方网站等平台公开名册信息，方便当事人查询。</p>
        <p>　　第九条人民法院可以设立家事、交通事故、医疗纠纷等专业调解委员会，并根据特定专业领域的纠纷特点，设定专业调解委员会的入册条件，规范专业领域特邀调解程序。</p>
        <p>　　第十条人民法院应当建立特邀调解组织和特邀调解员业绩档案，定期组织开展特邀调解评估工作，并及时更新名册信息。</p>
        <p>　　第十一条对适宜调解的纠纷，登记立案前，人民法院可以经当事人同意委派给特邀调解组织或者特邀调解员进行调解;登记立案后或者在审理过程中，可以委托给特邀调解组织或者特邀调解员进行调解。</p>
        <p>　　当事人申请调解的，应当以口头或者书面方式向人民法院提出；当事人口头提出的，人民法院应当记入笔录。</p>
        <p>　　第十二条双方当事人应当在名册中协商确定特邀调解员；协商不成的，由特邀调解组织或者人民法院指定。当事人不同意指定的，视为不同意调解。</p>
        <p>　　第十三条特邀调解一般由一名调解员进行。对于重大、疑难、复杂或者当事人要求由两名以上调解员共同调解的案件，可以由两名以上调解员调解，并由特邀调解组织或者人民法院指定一名调解员主持。当事人有正当理由的，可以申请更换特邀调解员。</p>
        <p>　　第十四条调解一般应当在人民法院或者调解组织所在地进行，双方当事人也可以在征得人民法院同意的情况下选择其他地点进行调解。</p>
        <p>　　特邀调解组织或者特邀调解员接受委派或者委托调解后，应当将调解时间、地点等相关事项及时通知双方当事人，也可以通知与纠纷有利害关系的案外人参加调解。</p>
        <p>　　调解程序开始之前，特邀调解员应当告知双方当事人权利义务、调解规则、调解程序、调解协议效力、司法确认申请等事项。</p>
        <p>　　第十五条特邀调解员有下列情形之一的，当事人有权申请回避：</p>
        <p>　　（一）是一方当事人或者其代理人近亲属的；</p>
        <p>　　（二）与纠纷有利害关系的；</p>
        <p>　　（三）与纠纷当事人、代理人有其他关系，可能影响公正调解的。</p>
        <p>　　特邀调解员有上述情形的，应当自行回避；但是双方当事人同意由该调解员调解的除外。</p>
        <p>　　特邀调解员的回避由特邀调解组织或者人民法院决定。</p>
        <p>　　第十六条特邀调解员不得在后续的诉讼程序中担任该案的人民陪审员、诉讼代理人、证人、鉴定人以及翻译人员等。</p>
        <p>　　第十七条特邀调解员应当根据案件具体情况采用适当的方法进行调解，可以提出解决争议的方案建议。特邀调解员为促成当事人达成调解协议，可以邀请对达成调解协议有帮助的人员参与调解。</p>
        <p>　　第十八条特邀调解员发现双方当事人存在虚假调解可能的，应当中止调解，并向人民法院或者特邀调解组织报告。</p>
        <p>　　人民法院或者特邀调解组织接到报告后，应当及时审查，并依据相关规定作出处理。</p>
        <p>　　第十九条委派调解达成调解协议，特邀调解员应当将调解协议送达双方当事人，并提交人民法院备案。</p>
        <p>　　委派调解达成的调解协议，当事人可以依照民事诉讼法、人民调解法等法律申请司法确认。当事人申请司法确认的，由调解组织所在地或者委派调解的基层人民法院管辖。</p>
        <p>　　第二十条委托调解达成调解协议，特邀调解员应当向人民法院提交调解协议，由人民法院审查并制作调解书结案。达成调解协议后，当事人申请撤诉的，人民法院应当依法作出裁定。</p>
        <p>　　第二十一条委派调解未达成调解协议的，特邀调解员应当将当事人的起诉状等材料移送人民法院；当事人坚持诉讼的，人民法院应当依法登记立案。</p>
        <p>　　委托调解未达成调解协议的，转入审判程序审理。</p>
        <p>　　第二十二条在调解过程中，当事人为达成调解协议作出妥协而认可的事实，不得在诉讼程序中作为对其不利的根据，但是当事人均同意的除外。</p>
        <p>　　第二十三条经特邀调解组织或者特邀调解员调解达成调解协议的，可以制作调解协议书。当事人认为无需制作调解协议书的，可以采取口头协议方式，特邀调解员应当记录协议内容。</p>
        <p>　　第二十四条调解协议书应当记载以下内容：</p>
        <p>　　（一）当事人的基本情况；</p>
        <p>　　（二）纠纷的主要事实、争议事项；</p>
        <p>　　（三）调解结果。</p>
        <p>　　双方当事人和特邀调解员应当在调解协议书或者调解笔录上签名、盖章或者捺印；由特邀调解组织主持达成调解协议的，还应当加盖调解组织印章。</p>
        <p>　　委派调解达成调解协议，自双方当事人签名、盖章或者捺印后生效。委托调解达成调解协议，根据相关法律规定确定生效时间。</p>
        <p>　　第二十五条委派调解达成调解协议后，当事人就调解协议的履行或者调解协议的内容发生争议的，可以向人民法院提起诉讼，人民法院应当受理。一方当事人以原纠纷向人民法院起诉，对方当事人以调解协议提出抗辩的，应当提供调解协议书。</p>
        <p>　　经司法确认的调解协议，一方当事人拒绝履行或者未全部履行的，对方当事人可以向人民法院申请执行。</p>
        <p>　　第二十六条有下列情形之一的，特邀调解员应当终止调解：</p>
        <p>　　（一）当事人达成调解协议的；</p>
        <p>　　（二）一方当事人撤回调解请求或者明确表示不接受调解的；</p>
        <p>　　（三）特邀调解员认为双方分歧较大且难以达成调解协议的；</p>
        <p>　　（四）其他导致调解难以进行的情形。</p>
        <p>　　特邀调解员终止调解的，应当向委派、委托的人民法院书面报告，并移送相关材料。</p>
        <p>　　第二十七条人民法院委派调解的案件，调解期限为30日。但是双方当事人同意延长调解期限的，不受此限。</p>
        <p>　　人民法院委托调解的案件，适用普通程序的调解期限为15日，适用简易程序的调解期限为７日。但是双方当事人同意延长调解期限的，不受此限。延长的调解期限不计入审理期限。</p>
        <p>　　委派调解和委托调解的期限自特邀调解组织或者特邀调解员签字接收法院移交材料之日起计算。</p>
        <p>　　第二十八条特邀调解员不得有下列行为：</p>
        <p>　　（一）强迫调解；</p>
        <p>　　（二）违法调解；</p>
        <p>　　（三）接受当事人请托或收受财物；</p>
        <p>　　（四）泄露调解过程或调解协议内容；</p>
        <p>　　（五）其他违反调解员职业道德的行为。</p>
        <p>　　当事人发现存在上述情形的，可以向人民法院投诉。经审查属实的，人民法院应当予以纠正并作出警告、通报、除名等相应处理。</p>
        <p>　　第二十九条人民法院应当根据实际情况向特邀调解员发放误工、交通等补贴，对表现突出的特邀调解组织和特邀调解员给予物质或者荣誉奖励。补贴经费应当纳入人民法院专项预算。</p>
        <p>　　人民法院可以根据有关规定向有关部门申请特邀调解专项经费。</p>
        <p>　　第三十条本规定自2016年7月1日起施行。</p>
      </div>
    </div>
  )
}

export default React.memo(DisputeGuide);